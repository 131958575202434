// src/components/IPDisplay.js

import React, { useEffect, useState } from 'react';
import './IPDisplay.css'; // Import CSS for styling

function IPDisplay() {
  const [ip, setIp] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Hard-coded API endpoint
  const API_ENDPOINT = 'https://ip.40hex.io/dev/getUserIP'

  // Function to handle GET request
  const fetchUserIP = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setIp(data.sourceIP);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching IP:', err);
      setError('Failed to fetch IP address.');
      setLoading(false);
    }
  };

  // Fetch IP on component mount
  useEffect(() => {
    fetchUserIP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ip-display-container">
      {loading ? (
        <p>Loading your IP address...</p>
      ) : error ? (
        <p className="error">{error}</p>
      ) : (
        <p className="ip-text">Your IP: {ip}</p>
      )}
    </div>
  );
}

export default IPDisplay;
