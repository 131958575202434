import React, { useState } from 'react';
import IPDisplay from './components/IPDisplay';
import axios from 'axios';
import './App.css';

function App() {
  const [target, setTarget] = useState('');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [darkMode, setDarkMode] = useState(true);

  const apiEndpoint = 'https://api.40hex.io/lookup'; // Replace with your API Gateway URL

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setData(null);
    try {
      const response = await axios.get(apiEndpoint, {
        params: { target },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setData(response.data);
    } catch (err) {
      setError(err.response?.data || 'An error occurred.');
    }
    setLoading(false);
  };

  // Get current year dynamically
  const currentYear = new Date().getFullYear();

  return (
    <div className={darkMode ? 'App dark' : 'App'}>
      <header className="App-header">
        <h1>40hex: DNS tools</h1>
        <button onClick={() => setDarkMode(!darkMode)} className="toggle-btn">
          {darkMode ? 'Light Mode' : 'Dark Mode'}
        </button>
      </header>
      <main>
        <IPDisplay />
	<form onSubmit={handleSubmit} className="lookup-form">
          <input
            type="text"
            value={target}
            onChange={(e) => setTarget(e.target.value)}
            placeholder="Enter domain or IP"
            required
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Searching...' : 'Lookup'}
          </button>
        </form>
        {error && <div className="error">Error: {error.error || error}</div>}
        {data && (
          <div className="results">
          {/* DNS Records Section */}
            {data.dns && (
              <section>
                <h2>DNS Records</h2>
                {Object.entries(data.dns).map(([type, records]) => (
                  <div key={type}>
                    <h3>{type} Records</h3>
                    {Array.isArray(records) ? (
                      records.length > 0 ? (
                        <ul>
                          {records.map((record, idx) => (
                            <li key={idx}>{record}</li>
                          ))}
                        </ul>
                      ) : (
                        <p>No {type} records found.</p>
                      )
                    ) : (
                      <p>{records}</p>
                    )}
                  </div>
                ))}
              </section>
            )}

            {/* Reverse DNS Section (only for IP lookups) */}
            {data.reverse_dns && (
              <section>
                <h2>Reverse DNS</h2>
                {Array.isArray(data.reverse_dns) ? (
                  data.reverse_dns.length > 0 ? (
                    <ul>
                      {data.reverse_dns.map((rd, idx) => (
                        <li key={idx}>{rd}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>No reverse DNS records found.</p>
                  )
                ) : (
                  <p>{data.reverse_dns}</p>
                )}
              </section>
            )}

            {/* WHOIS Information Section */}
            {data.whois && (
              <section>
                <h2>WHOIS Information</h2>
                <pre>{typeof data.whois === 'string' ? data.whois : JSON.stringify(data.whois, null, 2)}</pre>
              </section>
            )}
          </div>
        )}
      </main>
      <footer>
        <p>© {currentYear} 40hex: DNS</p>
      </footer>
    </div>
  );
}

export default App;
